// _gerneral.scss

sup {
    font-size: 40%;
    vertical-align: super;
}

@include media-breakpoint-down(lg) {
    .navbar-collapse {
        margin: 20px 0;
        height: 100vh;
    }

    #navbarNav .nav-item, .dropdown-item {
        border-bottom: solid 1px #666;

        .nav-link {
            padding-left: 10px;
            font-weight: 600;
        }

        .dropdown-toggle.active {
            font-weight: 900;
            background-color: rgba(255, 255, 255, 0.25);
        }

        .dropdown-item.active {
            font-weight: 600;
            color: red;
            background-color: transparent;
        }

        .dropdown-menu {
            display: block;
            margin: 0 0px 20px 0px;
            padding: 0;
            border-radius: 0;
            border: none;
            // background-color: transparent;
        }
    }

    .dropdown-menu li:last-child .dropdown-item {
        border-bottom: none;
    }
}

@include media-breakpoint-up(lg) {

    a.dropdown-toggle:hover, .nav-link:hover {
        color: #000;
    }

    a.dropdown-toggle.active, .nav-link.active {
        font-weight: 700;
    }

    .bi-house-door-fill {
        color: #666;

        &:hover {
            color: #000;
        }
    }

    .active .bi-house-door-fill {
        color: #000;
    }
}

    footer {
        font-size: .75rem;
        text-align: center;
    }

    // OneTrust Button styling overrides 
    #ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
        color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
        text-decoration: underline;
        border: 0 !important;
        background-color: transparent !important;
        padding: 0 !important;
        font-size: unset !important;
    }