$enable-gradients: true;
// $enable-responsive-font-sizes: true;
$enable-negative-margins: true;

// Greys
$white: #fff;
$light: #f8f9fa;
$ltgrey: #f2f2f2;
$grey: #ced4da;
$dkgrey: #6c757d;
$dark: #343a40;
$black: #000;

// Helper colors
$link-color: #0075c9;
$active: $link-color;
$accent-blue: $link-color;

// Font styling
$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 3.0;
$h2-font-size: $font-size-base * 2.0;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $dark;
$breadcrumb-active-color: $dkgrey;